<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style lang="less" scoped>
.home {
  padding: 15px;
  .van-button {
    margin: 5px;
  }
}
</style>
