import { http } from "../http/http";
import sss from "sha1";

import wx from "weixin-js-sdk";

import Cookies from "js-cookie";

///获取公众号token
const getToken = async () => {
  let result = await http.get("/user/wx-curl/token");
  if (result.code == 0) {
    return result.data.data.access_token;
  } else {
    return "获取access_token失败";
  }
};

///获取ticket 生成签名使用
const getSignature = async (access_token) => {
  let result = await http.get("/user/wx-curl/signature", {
    token: access_token,
    type: "jsapi",
  });
  if (result.code == 0) {
    return result.data.data.ticket;
  } else {
    return "获取ticket失败";
  }
};

export const configWxShare = {
  wxH5Share(title, desc, link, imageUrl) {
    let shareData = {
      title: title, //分享的标题
      desc: desc, //分享的描述
      link: link, //分享的链接
      imgUrl: imageUrl, //分享的图片链接
      success(response) {
        console.log("配置成功");
      },
    };
    console.log(shareData, "我是二次分享参数");
    wx.updateAppMessageShareData(shareData);
    wx.onMenuShareAppMessage(shareData);
    wx.updateTimelineShareData(shareData);
    wx.onMenuShareTimeline(shareData);
  },
};

const wxconfig = async () => {
  ///获取签名随机字符串
  function randomString(len) {
    len = len || 32;
    let $chars =
      "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = $chars.length;
    let pwd = "";
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }

  let wxTicket = "";

  if (Cookies.get("wx_ticket")) {
    wxTicket = Cookies.get("wx_ticket");
  } else {
    console.log("获取新的ticket");
    const access_token = await getToken();
    wxTicket = await getSignature(access_token);
    let expires = new Date();
    expires.setHours(expires.getHours() + 2);
    Cookies.set("wx_ticket", wxTicket, { expires: expires });
  }

  ///随机字符串码
  const noncestr = randomString(32);
  // console.log(noncestr, '当前获取的随机串');
  ///时间戳
  const timestamp = Math.ceil(new Date().getTime() / 1000);
  // console.log(timestamp, '当前获取的时间戳');
  ///使用ticket生成签名,签名看起来像是使用sha64加密
  const url = `jsapi_ticket=${wxTicket}&noncestr=${noncestr}&timestamp=${timestamp}&url=${
    location.href.split("#")[0]
  }`;

  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
    appId: "wx650344a09eda8439", // 必填，公众号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: noncestr, // 必填，生成签名的随机串
    signature: sss(url), // 必填，签名
    jsApiList: [
      "onMenuShareTimeline", // 分享给好友
      "onMenuShareAppMessage",
      "updateAppMessageShareData",
      "updateTimelineShareData",
    ], // 必填，需要使用的JS接口列表
    openTagList: ["wx-open-launch-app", "wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
  });

  wx.ready(function () {});

  wx.error(function (res) {
    // console.log(res, '错误信息');
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
  });
};

export default wxconfig;
